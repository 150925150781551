import { gql, useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { notEmpty } from '@havppen/utils/src/array'
import { useEffect, useMemo, useState } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import {
  GET_ARTICLE,
  GET_ARTICLES,
  GET_ARTICLES_WITH_CATEGORIES,
  GET_ARTICLE_CATEGORIES,
  GET_ARTICLE_PREVIEW,
  GET_ARTICLE_RANDOM,
  GET_ARTICLE_STAT,
  GET_ARTICLE_TAGS,
} from 'src/gql/article'
import { ArticleBasicProps, ArticleCategoryListProps, ArticlePaidContentType, ArticleProps } from 'src/types/article'
import { NIL as NIL_UUID, validate as isUUID } from 'uuid'
import {
  ArticleOrderType,
  formatGqlArticles,
  formatGqlArticlesWithCategories,
  mapArticlesQueryOptionsToGqlVariables,
  mapArticlesWithCategoriesQueryOptionsToGqlVariables,
} from './article'

export const useArticles = (options?: {
  orderBy?: ArticleOrderType
  limit?: number
  offset?: number
  tagName?: string
  authorName?: string
  authorId?: string
  searchQuery?: string
  categorySlug?: string | null
  categorySlugs?: string[]
  categoryId?: string
  articleIds?: string[]
  filterIds?: string[]
  isVideoExists?: boolean
}) => {
  const { defaultAvatarUrl, id: appId } = useApp()
  const variables = mapArticlesQueryOptionsToGqlVariables(options || {}, { appId })
  const { loading, error, data, refetch, fetchMore } = useQuery<types.GET_ARTICLES, types.GET_ARTICLESVariables>(
    GET_ARTICLES,
    {
      variables,
      context: { important: true },
    },
  )

  const [isNoMore, setIsNoMore] = useState(false)
  const [hasPrioritizedArticles, setHasPrioritizedArticles] = useState(false)

  const articles: ArticleBasicProps[] = useMemo(() => {
    return formatGqlArticles(data, defaultAvatarUrl)
  }, [data, defaultAvatarUrl])

  const articleCount = data?.article_aggregate.aggregate?.count || 0
  const nonPrioritizedArticles = articles.filter(article => article.weight === 0)
  useEffect(() => {
    if (options?.limit && options.limit >= articleCount) {
      setIsNoMore(true)
    } else if (!options?.limit) {
      setIsNoMore(true)
    }
  }, [articleCount, options?.limit])

  const [isFetchingMoreArticles, setIsFetchingMoreArticles] = useState(false)

  return {
    articleCount,
    loadingArticles: loading,
    errorArticles: error,
    articles,
    refetchArticles: refetch,
    isNoMoreArticles: isNoMore,
    isFetchingMoreArticles,
    fetchMoreArticles: isNoMore
      ? undefined
      : async () => {
          setIsFetchingMoreArticles(true)
          try {
            await fetchMore({
              variables: {
                ...variables,
                condition: {
                  ...variables.condition,
                  id: hasPrioritizedArticles
                    ? { _nin: [...(options?.filterIds || []), ...articles.map(article => article.id)] }
                    : undefined,
                  online_at:
                    nonPrioritizedArticles.length > 0
                      ? { _lt: nonPrioritizedArticles[nonPrioritizedArticles.length - 1]?.onlineAt }
                      : undefined,
                  weight: hasPrioritizedArticles ? undefined : { _eq: 0 },
                },
              },
              updateQuery(prev, { fetchMoreResult }) {
                if (!fetchMoreResult) {
                  return prev
                }
                if (fetchMoreResult.article.length < variables.limit) {
                  setIsNoMore(true)
                }

                const hasPrioritizedArticles =
                  fetchMoreResult.article.filter(article => (article.weight || 0) > 0).length > 0
                setHasPrioritizedArticles(hasPrioritizedArticles)

                return {
                  ...prev,
                  article: [...(prev?.article || []), ...fetchMoreResult.article],
                }
              },
            })
          } catch (error) {
            console.error(error)
          }
          setIsFetchingMoreArticles(false)
        },
  }
}

export const useArticleStats = (options?: {
  skip?: boolean
  tagName?: string
  authorId?: string
  authorName?: string
  searchQuery?: string
  categoryAccessSlug?: string | null
  categorySlug?: string | null
  filterIds?: string[]
}) => {
  const condition: types.article_bool_exp = {
    status: { _eq: 'published' },
    article_tags: options?.tagName ? { tag: { name: { _eq: options.tagName } } } : undefined,
    author_id: options?.authorId ? { _eq: options.authorId } : undefined,
    author: options?.authorName
      ? {
          _or: [{ name: { _like: `%${options.authorName}%` } }, { display_name: { _like: `%${options.authorName}%` } }],
        }
      : undefined,
    id: options?.filterIds ? { _nin: options.filterIds } : undefined,
    article_category_accesses: options?.categoryAccessSlug
      ? { category: { slug: { _eq: options.categoryAccessSlug } } }
      : undefined,
    article_categories: options?.categorySlug ? { category: { slug: { _eq: options.categorySlug } } } : undefined,
    _or: options?.searchQuery
      ? [
          { title: { _like: options.searchQuery } },
          { content: { _like: `%${options.searchQuery}%` } },
          { member: { name: { _like: `%${options.searchQuery}%` } } },
          { member: { display_name: { _like: `%${options.searchQuery}%` } } },
        ]
      : undefined,
  }

  const { loading, error, data } = useQuery<types.GET_ARTICLE_STAT, types.GET_ARTICLE_STATVariables>(GET_ARTICLE_STAT, {
    skip: options?.skip,
    variables: {
      condition,
    },
  })

  return {
    loadingArticleStats: loading,
    errorArticleStats: error,
    articleStats:
      loading || error || !data || !data.article_aggregate.aggregate?.count
        ? 0
        : data.article_aggregate.aggregate.count,
  }
}

export const useArticlesWithCategories = (options?: {
  limit?: number
  categorySlugs?: string[]
  parentCategorySlug?: string
}) => {
  const { id: appId, defaultAvatarUrl } = useApp()
  const limit = options?.limit || 3

  const { loading, error, data, refetch } = useQuery<
    types.GET_ARTICLES_WITH_CATEGORIES,
    types.GET_ARTICLES_WITH_CATEGORIESVariables
  >(GET_ARTICLES_WITH_CATEGORIES, {
    variables: mapArticlesWithCategoriesQueryOptionsToGqlVariables(
      {
        limit,
        categorySlugs: options?.categorySlugs,
        parentCategorySlug: options?.parentCategorySlug,
      },
      { appId },
    ),
  })

  const categories: ArticleCategoryListProps[] = useMemo(() => {
    return formatGqlArticlesWithCategories(data, defaultAvatarUrl)
  }, [data, defaultAvatarUrl])

  return {
    loadingArticleCategories: loading,
    errorArticleCategories: error,
    categories,
    refetchArticleCategories: refetch,
  }
}

export type ArticleCategoryProps = {
  id: string
  name: string
  slug: string
  publishedArticleCount: number
}
export const useArticleCategories = () => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_ARTICLE_CATEGORIES,
    types.GET_ARTICLE_CATEGORIESVariables
  >(GET_ARTICLE_CATEGORIES)

  const articleCategories: ArticleCategoryProps[] = useMemo(() => {
    return (data?.category || []).map(category => ({
      id: category.id,
      name: category.name,
      slug: category.slug,
      publishedArticleCount: category.article_category_accesses_aggregate.aggregate?.count || 0,
    }))
  }, [data])

  const [isFetchingArticleCategories, setIsFetchingArticleCategories] = useState(false)
  const refetchArticleCategories = async () => {
    setIsFetchingArticleCategories(true)
    refetch()
    setTimeout(() => {
      setIsFetchingArticleCategories(false)
    }, 500)
  }

  return {
    loadingArticleCategories: loading,
    errorArticleCategories: error,
    articleCategories,
    isFetchingArticleCategories,
    refetchArticleCategories,
  }
}

export const useArticle = (articleIdOrSlug: string) => {
  const { defaultAvatarUrl } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_ARTICLE, types.GET_ARTICLEVariables>(GET_ARTICLE, {
    variables: {
      condition: {
        id: isUUID(articleIdOrSlug) ? { _eq: articleIdOrSlug } : undefined,
        slug: isUUID(articleIdOrSlug) ? undefined : { _eq: articleIdOrSlug },
      },
    },
  })

  const article = useMemo(() => {
    return ((data?.article ?? []).map(article => {
      const categories = [
        ...article.article_categories.map(article_category => article_category.category),
        ...article.app_sharing_categories.map(article_category => article_category.category),
      ].filter(notEmpty)
      const articleSlug = article.slug ?? article.id
      const path = `/article/${articleSlug}`

      return {
        id: article.id,
        path,
        slug: article.slug,
        title: article.title,
        abstract: article.abstract,
        weight: article.weight,
        author: mapMemberPublicFromGql(article.author, { defaultAvatarUrl }),
        featuredImageUrl: article.featured_image_url,
        coverVideoUrl: article.cover_video_url,
        paidContentType: (article.paid_content_type ?? 'public') as ArticlePaidContentType,
        content: article.content,
        onlineAt: article.online_at ? new Date(article.online_at) : null,
        offlineAt: article.offline_at ? new Date(article.offline_at) : null,
        updatedAt: article.updated_at ? new Date(article.updated_at) : null,
        categories,
        status: article.status,
        tags: article.article_tags.map(article_tag => article_tag.tag.name),
        isExternalLinkEnabled: article.is_external_link_enabled ?? false,
        externalLink: article.external_link ?? null,
        seoAttributes: article.seo_attributes,
      }
    })[0] ?? null) as ArticleProps | null
  }, [data, defaultAvatarUrl])

  return {
    loadingArticle: loading,
    errorArticle: error,
    article,
    refetchArticle: refetch,
  }
}
export const useArticlePreview = (articleId: string) => {
  const { defaultAvatarUrl } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_ARTICLE_PREVIEW, types.GET_ARTICLE_PREVIEWVariables>(
    GET_ARTICLE_PREVIEW,
    {
      variables: {
        articleId,
      },
    },
  )

  const categories = data?.article_by_pk
    ? [
        ...data.article_by_pk.article_categories.map(article_category => article_category.category).filter(notEmpty),
        ...data.article_by_pk.app_sharing_categories
          .map(article_category => article_category.category)
          .filter(notEmpty),
      ]
    : []
  const articleSlug = data?.article_by_pk ? data.article_by_pk.slug ?? data.article_by_pk.id : null

  const article: ArticleProps | null =
    loading || error || !data || !data.article_by_pk
      ? null
      : {
          id: data.article_by_pk.id,
          path: categories[0] ? `/${categories[0].slug ?? categories[0].id}/${articleSlug}` : `/article/${articleSlug}`,
          slug: data.article_by_pk.slug,
          title: data.article_by_pk.title,
          abstract: data.article_by_pk.abstract,
          weight: data.article_by_pk.weight,
          author: mapMemberPublicFromGql(data.article_by_pk.author, { defaultAvatarUrl }),
          featuredImageUrl: data.article_by_pk.featured_image_url,
          coverVideoUrl: data.article_by_pk.cover_video_url,
          paidContentType: (data.article_by_pk.paid_content_type ?? 'public') as ArticlePaidContentType,
          onlineAt: data.article_by_pk.online_at ? new Date(data.article_by_pk.online_at) : null,
          offlineAt: data.article_by_pk.offline_at ? new Date(data.article_by_pk.offline_at) : null,
          updatedAt: data.article_by_pk.updated_at ? new Date(data.article_by_pk.updated_at) : null,
          categories,
          status: data.article_by_pk.status,
          tags: data.article_by_pk.article_tags.map(article_tag => article_tag.tag.name),
          content:
            new Date(data.article_by_pk.content_histories?.[0]?.created_at).getTime() >
            new Date(data.article_by_pk.updated_at || data.article_by_pk.created_at).getTime()
              ? data.article_by_pk.content_histories[0].content
              : data.article_by_pk.content,
          isExternalLinkEnabled: data.article_by_pk.is_external_link_enabled ?? false,
          externalLink: data.article_by_pk.external_link ?? null,
        }

  return {
    loadingArticle: loading,
    errorArticle: error,
    article,
    refetchArticle: refetch,
  }
}
export const useArticleRandom = (currentArticleId?: string) => {
  const { defaultAvatarUrl } = useApp()
  const { loading, error, data, refetch, fetchMore } = useQuery<
    types.GET_ARTICLE_RANDOM,
    types.GET_ARTICLE_RANDOMVariables
  >(GET_ARTICLE_RANDOM, {
    variables: {
      condition: {
        article_id: currentArticleId ? { _neq: currentArticleId } : undefined,
      },
      limit: 0,
    },
  })

  const [isNoMore, setIsNoMore] = useState(false)

  const articles: ArticleProps[] = useMemo(() => {
    return (data?.article_random ?? [])
      .filter(article => notEmpty(article.id))
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(article => {
        const categories = [
          ...article.article_categories.map(article_category => article_category.category).filter(notEmpty),
          ...article.app_sharing_categories.map(article_category => article_category.category).filter(notEmpty),
        ]
        const articleSlug = article.slug ?? article.id
        const path = `/article/${articleSlug}`

        return {
          id: article.id as string,
          path,
          slug: article.slug,
          title: article.title as string,
          abstract: article.abstract,
          weight: article.weight,
          author: mapMemberPublicFromGql(article.author, { defaultAvatarUrl }),
          featuredImageUrl: article.featured_image_url,
          coverVideoUrl: article.cover_video_url,
          paidContentType: (article.paid_content_type ?? 'public') as ArticlePaidContentType,
          onlineAt: article.online_at ? new Date(article.online_at) : null,
          offlineAt: article.offline_at ? new Date(article.offline_at) : null,
          updatedAt: article.updated_at ? new Date(article.updated_at) : null,
          categories,
          tags: article.article_tags.map(article_tag => article_tag.tag.name),
          content: article.content,
          status: 'published',
          isExternalLinkEnabled: article.is_external_link_enabled ?? false,
          externalLink: article.external_link ?? null,
        }
      })
  }, [data, defaultAvatarUrl])

  const articleIds = currentArticleId
    ? [...articles.map(article => article.id), currentArticleId]
    : articles.map(article => article.id)

  return {
    loadingArticle: loading,
    errorArticle: error,
    articles,
    refetchArticles: refetch,
    fetchMoreArticles: isNoMore
      ? undefined
      : () =>
          typeof fetchMore !== 'undefined'
            ? fetchMore?.({
                variables: {
                  condition: {
                    id: { _nin: articleIds },
                  },
                  limit: 1,
                },
                updateQuery(prev, { fetchMoreResult }) {
                  if (!fetchMoreResult) {
                    return prev
                  }
                  if (fetchMoreResult.article_random.length < 1) {
                    setIsNoMore(true)
                  }
                  return {
                    ...prev,
                    article_random: [...(prev?.article_random || []), ...fetchMoreResult.article_random],
                  }
                },
              })
            : undefined,
  }
}

export const useArticleTags = ({ limit = 5, categorySlug }: { limit: number | null; categorySlug?: string | null }) => {
  const { id: appId } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_ARTICLE_TAGS, types.GET_ARTICLE_TAGSVariables>(
    GET_ARTICLE_TAGS,
    {
      variables: {
        limit,
        condition: {
          tag: { app_id: { _eq: appId } },
          article: {
            status: { _eq: 'published' },
            article_categories: categorySlug ? { category: { slug: { _eq: categorySlug } } } : undefined,
          },
        },
      },
    },
  )

  const tags: string[] = loading || error || !data ? [] : data.article_tag.map(article_tag => article_tag.tag.name)

  return {
    loadingTags: loading,
    errorTags: error,
    tags,
    refetchTags: refetch,
  }
}
export const useArticleCollections = (memberId: string, options?: { limit?: number; offset?: number }) => {
  const { defaultAvatarUrl } = useApp()
  const [isNoMore, setIsNoMore] = useState(false)
  const { loading, error, data, refetch, fetchMore } = useQuery<types.GET_ARTICLES, types.GET_ARTICLESVariables>(
    GET_ARTICLES,
    {
      variables: {
        orderBy: [{ weight: 'desc' as types.order_by }, { online_at: 'desc' as types.order_by }],
        limit: options?.limit,
        offset: options?.offset,
        condition: {
          member_article_collections: { member_id: { _eq: memberId } },
        },
      },
    },
  )

  const articles: ArticleBasicProps[] =
    loading || error || !data
      ? []
      : data.article.map(article => {
          const categories = [
            ...article.article_categories.map(article_category => article_category.category).filter(notEmpty),
            ...article.app_sharing_categories.map(article_category => article_category.category).filter(notEmpty),
          ]
          const articleSlug = article.slug ?? article.id
          const path = `/article/${articleSlug}`

          return {
            id: article.id,
            path,
            slug: article.slug,
            title: article.title,
            abstract: article.abstract,
            weight: article.weight,
            author: mapMemberPublicFromGql(article.author, { defaultAvatarUrl }),
            featuredImageUrl: article.featured_image_url,
            coverVideoUrl: article.cover_video_url,
            paidContentType: (article.paid_content_type ?? 'public') as ArticlePaidContentType,
            onlineAt: article.online_at ? new Date(article.online_at) : null,
            updatedAt: article.updated_at ? new Date(article.updated_at) : null,
            categories,
            tags: article.article_tags.map(article_tag => article_tag.tag.name),
            isExternalLinkEnabled: article.is_external_link_enabled ?? false,
            externalLink: article.external_link ?? null,
          }
        })

  const articleCount = data?.article_aggregate.aggregate?.count || 0
  useEffect(() => {
    if (options?.limit && options.limit >= articleCount) {
      setIsNoMore(true)
    } else if (!options?.limit) {
      setIsNoMore(true)
    }
  }, [articleCount, options?.limit])

  return {
    articleCount,
    loadingArticles: loading,
    errorArticles: error,
    articles,
    refetchArticles: async () => {
      setIsNoMore(false)
      await refetch()
    },
    fetchMoreArticles: isNoMore
      ? undefined
      : () =>
          fetchMore({
            variables: { offset: data?.article.length || 0 },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev
              }
              if (fetchMoreResult.article.length < (options?.limit || 10)) {
                setIsNoMore(true)
              }
              return {
                ...prev,
                article: [...prev.article, ...fetchMoreResult.article],
              }
            },
          }),
  }
}

export const useArticleCreations = (memberId: string, options?: { limit?: number; offset?: number }) => {
  const { defaultAvatarUrl } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_ARTICLES, types.GET_ARTICLESVariables>(GET_ARTICLES, {
    variables: {
      orderBy: [{ weight: 'desc' as types.order_by }, { online_at: 'desc' as types.order_by }],
      limit: options?.limit,
      offset: options?.offset,
      condition: {
        _or: [{ member_id: { _eq: memberId } }, { author_id: { _eq: memberId } }],
      },
    },
  })

  const articles: ArticleBasicProps[] =
    loading || error || !data
      ? []
      : data.article.map(article => {
          const categories = [
            ...article.article_categories.map(article_category => article_category.category).filter(notEmpty),
            ...article.app_sharing_categories.map(article_category => article_category.category).filter(notEmpty),
          ]
          const articleSlug = article.slug ?? article.id
          const path = `/article/${articleSlug}`

          return {
            id: article.id,
            path,
            slug: article.slug,
            title: article.title,
            abstract: article.abstract,
            weight: article.weight,
            author: mapMemberPublicFromGql(article.author, { defaultAvatarUrl }),
            featuredImageUrl: article.featured_image_url,
            coverVideoUrl: article.cover_video_url,
            paidContentType: (article.paid_content_type ?? 'public') as ArticlePaidContentType,
            onlineAt: article.online_at ? new Date(article.online_at) : null,
            updatedAt: article.updated_at ? new Date(article.updated_at) : null,
            categories,
            tags: article.article_tags.map(article_tag => article_tag.tag.name),
            isExternalLinkEnabled: article.is_external_link_enabled ?? false,
            externalLink: article.external_link ?? null,
          }
        })
  const articleCount = data?.article_aggregate.aggregate?.count || 0

  return {
    articleCount,
    loadingArticles: loading,
    errorArticles: error,
    articles,
    refetchArticles: refetch,
  }
}

export const useMemberArticleCollectionCount = (options: { memberId: string }) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_ARTICLE_COLLECTION_COUNT,
    types.GET_MEMBER_ARTICLE_COLLECTION_COUNTVariables
  >(
    gql`
      query GET_MEMBER_ARTICLE_COLLECTION_COUNT($memberId: String!) {
        member_article_collection_aggregate(where: { member_id: { _eq: $memberId } }) {
          aggregate {
            count
          }
        }
      }
    `,
    {
      skip: options.memberId === NIL_UUID,
      variables: { memberId: options.memberId },
    },
  )

  const memberArticleCollectionCount =
    loading || error || !data ? 0 : data.member_article_collection_aggregate.aggregate?.count || 0
  return {
    loadingMemberArticleCollectionCount: loading,
    errorMemberArticleCollectionCount: error,
    memberArticleCollectionCount,
    refetchMemberArticleCollectionCount: refetch,
  }
}

export const useMainArticleSlugs = () => {
  const { navs } = useApp()
  const mainNavSlugs = useMemo(() => {
    return navs
      .map(nav => nav.href)
      .filter(href => href.startsWith('/articles'))
      .map(href => href.split('/')[2])
  }, [navs])

  return mainNavSlugs
}

export const useArticlePaidContent = (articleIdOrSlug: string) => {
  const { isAuthenticated } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_ARTICLE_PAID_CONTENT,
    types.GET_ARTICLE_PAID_CONTENTVariables
  >(
    gql`
      query GET_ARTICLE_PAID_CONTENT($condition: article_paid_content_bool_exp!) {
        article_paid_content(where: $condition, limit: 1) {
          paid_content
        }
      }
    `,
    {
      skip: !isAuthenticated || !articleIdOrSlug,
      variables: {
        condition: {
          article_id: isUUID(articleIdOrSlug) ? { _eq: articleIdOrSlug } : undefined,
          article_slug: isUUID(articleIdOrSlug) ? undefined : { _eq: articleIdOrSlug },
        },
      },
    },
  )

  const articlePaidContent = useMemo(
    () => (data && data.article_paid_content.length > 0 ? data.article_paid_content[0]?.paid_content : null),
    [data],
  )

  return {
    loadingArticlePaidContent: loading,
    errorArticlePaidContent: error,
    articlePaidContent,
    refetchArticlePaidContent: refetch,
  }
}
