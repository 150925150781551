import { gql } from '@apollo/client'
import { MEMBER_PUBLIC_COLUMNS } from '@havppen/gql/src/member'

export const GET_ARTICLE_IDS_WITH_HOST_CATEGORY = gql`
  query GET_ARTICLE_IDS_WITH_HOST_CATEGORY($limit: Int) {
    category(where: { article_category_accesses: {} }) {
      id
      slug
      article_category_accesses(limit: $limit, where: { article: { status: { _eq: "published" } } }) {
        article {
          id
          slug
          app {
            app_domains(where: { default: { _eq: true }, type: { _eq: "app" } }) {
              domain
            }
          }
        }
      }
    }
  }
`

export const GET_ARTICLE_IDS_WITH_HOST = gql`
  query GET_ARTICLE_IDS_WITH_HOST($limit: Int) {
    article(where: { _not: { article_categories: {} } }, limit: $limit) {
      id
      slug
      app {
        app_domains(where: { default: { _eq: true }, type: { _eq: "app" } }) {
          domain
        }
      }
    }
  }
`

export const ARTICLE_PUBLIC_COLUMNS = gql`
  fragment ARTICLE_PUBLIC_COLUMNS on article {
    id
    slug
    title
    abstract
    weight
    author {
      ...MEMBER_PUBLIC_COLUMNS
    }
    featured_image_url
    cover_video_url
    paid_content_type
    online_at
    updated_at

    article_tags(order_by: { position: asc }) {
      tag {
        name
      }
    }

    is_external_link_enabled: metadata(path: "isExternalLinkEnabled")
    external_link: metadata(path: "externalLink")
  }
  ${MEMBER_PUBLIC_COLUMNS}
`
export const ARTICLE_CATEGORY_COLUMN = gql`
  fragment ARTICLE_CATEGORY_COLUMN on article {
    article_categories {
      category {
        id
        name
        slug
      }
    }
    app_sharing_categories {
      category {
        id
        name
        slug
      }
    }
  }
`
export const GET_ARTICLES = gql`
  query GET_ARTICLES($condition: article_bool_exp, $limit: Int, $offset: Int, $orderBy: [article_order_by!]) {
    article(where: $condition, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...ARTICLE_PUBLIC_COLUMNS
      ...ARTICLE_CATEGORY_COLUMN
    }
    article_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
  ${ARTICLE_PUBLIC_COLUMNS}
  ${ARTICLE_CATEGORY_COLUMN}
`
export const GET_ARTICLE_STAT = gql`
  query GET_ARTICLE_STAT($condition: article_bool_exp) {
    article_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`
export const GET_ARTICLES_WITH_CATEGORIES = gql`
  query GET_ARTICLES_WITH_CATEGORIES($condition: category_bool_exp, $limit: Int) {
    category(where: $condition, order_by: { position: asc }) {
      id
      name
      slug
      metadata
      article_category_accesses(
        limit: $limit
        where: { article: { status: { _eq: "published" } } }
        order_by: [{ article: { weight: desc } }, { article: { online_at: desc } }]
      ) {
        article {
          ...ARTICLE_PUBLIC_COLUMNS
        }
      }
    }
  }
  ${ARTICLE_PUBLIC_COLUMNS}
`

export const GET_ARTICLE_CATEGORIES = gql`
  query GET_ARTICLE_CATEGORIES {
    category(where: { type: { _eq: "article" } }) {
      id
      name
      slug
      article_category_accesses_aggregate(where: { article: { status: { _eq: "published" } } }) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_ARTICLE_BY_CATEGORY = gql`
  query GET_ARTICLE_BY_CATEGORY(
    $categorySlug: String!
    $limit: Int
    $orderBy: [article_category_access_order_by!]
    $onlineAtCursor: timestamptz
  ) {
    category(where: { slug: { _eq: $categorySlug } }) {
      id
      name
      slug
      article_category_accesses(
        where: { article: { status: { _eq: "published" }, online_at: { _lt: $onlineAtCursor } } }
        limit: $limit
        order_by: $orderBy
      ) {
        article {
          ...ARTICLE_PUBLIC_COLUMNS
        }
      }
    }
  }
  ${ARTICLE_PUBLIC_COLUMNS}
`
export const GET_ARTICLE = gql`
  query GET_ARTICLE($condition: article_bool_exp) {
    article(where: $condition, limit: 1) {
      ...ARTICLE_PUBLIC_COLUMNS
      ...ARTICLE_CATEGORY_COLUMN
      offline_at
      content
      status
      seo_attributes
    }
  }
  ${ARTICLE_PUBLIC_COLUMNS}
  ${ARTICLE_CATEGORY_COLUMN}
`
export const GET_ARTICLE_RANDOM = gql`
  query GET_ARTICLE_RANDOM($condition: article_random_bool_exp, $limit: Int) {
    article_random(where: $condition, limit: $limit) {
      id
      slug
      title
      abstract
      weight
      member {
        ...MEMBER_PUBLIC_COLUMNS
      }
      author {
        ...MEMBER_PUBLIC_COLUMNS
      }
      featured_image_url
      cover_video_url
      online_at
      updated_at
      paid_content_type
      article_tags {
        tag {
          name
        }
      }
      article_categories {
        category {
          id
          name
          slug
        }
      }
      app_sharing_categories {
        category {
          id
          name
          slug
        }
      }
      content
      offline_at
      is_external_link_enabled: metadata(path: "isExternalLinkEnabled")
      external_link: metadata(path: "externalLink")
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`
export const GET_ARTICLE_PREVIEW = gql`
  query GET_ARTICLE_PREVIEW($articleId: String!) {
    article_by_pk(id: $articleId) {
      ...ARTICLE_PUBLIC_COLUMNS
      ...ARTICLE_CATEGORY_COLUMN
      offline_at
      status
      content
      created_at
      updated_at
      content_histories(order_by: { created_at: desc }, limit: 1) {
        content
        created_at
      }
    }
  }
  ${ARTICLE_PUBLIC_COLUMNS}
  ${ARTICLE_CATEGORY_COLUMN}
`

export const GET_TAGS_BY_HOTTEST_ARTICLE = gql`
  query GET_TAGS_BY_HOTTEST_ARTICLE($limit: Int) {
    article(
      where: { status: { _eq: "published" } }
      limit: $limit
      order_by: { article_latest_pageview: { pageview: desc } }
    ) {
      article_tags {
        tag {
          name
        }
      }
    }
  }
`

export const GET_ARTICLE_TAGS = gql`
  query GET_ARTICLE_TAGS($condition: article_tag_bool_exp, $limit: Int, $orderBy: [article_tag_order_by!]) {
    article_tag(where: $condition, distinct_on: [tag_id], limit: $limit, order_by: $orderBy) {
      tag {
        name
      }
    }
  }
`
