import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { notEmpty } from '@havppen/utils/src/array'
import { ArticleCategoryListProps, ArticlePaidContentType } from 'src/types/article'

export type ArticleOrderType = 'latest' | 'hottest'

type ArticlesQueryOptions = {
  orderBy?: ArticleOrderType
  limit?: number
  offset?: number
  tagName?: string
  authorName?: string
  authorId?: string
  searchQuery?: string
  categorySlugs?: string[]
  categorySlug?: string | null
  categoryId?: string
  articleIds?: string[]
  filterIds?: string[]
  isVideoExists?: boolean
}
export const mapArticlesQueryOptionsToGqlVariables = (options: ArticlesQueryOptions, params: { appId: string }) => {
  const limit = options?.limit || 10
  const offset = options?.offset
  const orderBy: types.article_order_by[] | undefined =
    options?.orderBy === 'latest'
      ? [{ weight: 'desc' as types.order_by }, { online_at: 'desc' as types.order_by }]
      : options?.orderBy === 'hottest'
      ? [{ article_latest_pageview: { pageview: 'desc' as types.order_by } }]
      : undefined
  const condition: types.article_bool_exp = {
    status: { _eq: 'published' },
    cover_video_url: options?.isVideoExists ? { _is_null: false } : undefined,
    article_tags: options?.tagName ? { tag: { name: { _eq: options.tagName } } } : undefined,
    author: options?.authorName
      ? {
          _or: [{ name: { _eq: options.authorName } }, { display_name: { _eq: options.authorName } }],
        }
      : options?.authorId
      ? { id: { _eq: options.authorId } }
      : undefined,
    id: options?.filterIds
      ? { _nin: options.filterIds }
      : options?.articleIds
      ? { _in: options.articleIds }
      : undefined,
    article_app_accesses: { app_id: { _eq: params.appId } },
    article_category_accesses: options?.categorySlugs
      ? undefined
      : options?.categorySlug
      ? {
          category: {
            app_id: { _eq: params.appId },
            slug: { _eq: options.categorySlug },
          },
        }
      : options?.categoryId
      ? { category_id: { _eq: options.categoryId } }
      : undefined,
    _and: options.categorySlugs
      ? options.categorySlugs.map(categorySlug => ({
          article_categories: {
            category: {
              slug: { _eq: categorySlug },
            },
          },
        }))
      : undefined,
    _or: options?.searchQuery
      ? [
          { title: { _like: options.searchQuery } },
          { content: { _like: `%${options.searchQuery}%` } },
          { author: { name: { _like: `%${options.searchQuery}%` } } },
          { author: { display_name: { _like: `%${options.searchQuery}%` } } },
        ]
      : undefined,
  }
  const variables = {
    orderBy,
    limit,
    offset,
    condition,
  }

  return variables
}

export const formatGqlArticles = (data: types.GET_ARTICLES | undefined, defaultAvatarUrl?: string | null) => {
  return (data?.article || []).map(article => {
    const categories = [
      ...article.article_categories.map(article_category => article_category.category).filter(notEmpty),
      ...article.app_sharing_categories.map(article_category => article_category.category).filter(notEmpty),
    ]
    const articleSlug = article.slug ?? article.id
    const path = `/article/${articleSlug}`

    return {
      id: article.id,
      slug: article.slug,
      path,
      title: article.title,
      abstract: article.abstract,
      weight: article.weight,
      author: mapMemberPublicFromGql(article.author, { defaultAvatarUrl }),
      featuredImageUrl: article.featured_image_url,
      coverVideoUrl: article.cover_video_url,
      paidContentType: (article.paid_content_type ?? 'public') as ArticlePaidContentType,
      onlineAt: article.online_at ? new Date(article.online_at) : null,
      updatedAt: article.updated_at ? new Date(article.updated_at) : null,
      categories,
      tags: article.article_tags.map(article_tag => article_tag.tag.name),
      isExternalLinkEnabled: article.is_external_link_enabled ?? false,
      externalLink: article.external_link ?? null,
    }
  })
}

export const mapArticlesWithCategoriesQueryOptionsToGqlVariables = (
  options: { limit?: number; categorySlugs?: string[]; parentCategorySlug?: string },
  params: { appId: string },
) => {
  const condition: types.category_bool_exp = {
    app_id: { _eq: params.appId },
    slug: options.categorySlugs ? { _in: options.categorySlugs } : undefined,
    parent_category: options.parentCategorySlug ? { slug: { _eq: options.parentCategorySlug } } : undefined,
  }

  const variables = {
    limit: options.limit || 10,
    condition,
  }

  return variables
}
export const formatGqlArticlesWithCategories = (
  data: types.GET_ARTICLES_WITH_CATEGORIES | undefined,
  defaultAvatarUrl?: string | null,
) => {
  const categories: ArticleCategoryListProps[] = (data?.category || [])
    .filter(category => category.article_category_accesses.length > 0)
    .map(category => ({
      id: category.id,
      name: category.name,
      slug: category.slug,
      metadata: category.metadata,
      articles: category.article_category_accesses
        .map(article_category_access => article_category_access.article)
        .filter(notEmpty)
        .map(article => ({
          id: article.id as string,
          path: `/${category.slug ?? category.id}/${article.slug ?? article.id}`,
          slug: article.slug,
          title: article.title,
          abstract: article.abstract,
          weight: article.weight,
          author: mapMemberPublicFromGql(article.author, { defaultAvatarUrl }),
          featuredImageUrl: article.featured_image_url,
          coverVideoUrl: article.cover_video_url,
          paidContentType: (article.paid_content_type ?? 'public') as ArticlePaidContentType,
          onlineAt: article.online_at ? new Date(article.online_at) : null,
          updatedAt: article.updated_at ? new Date(article.updated_at) : null,
          categories: [
            {
              id: category.id as string,
              name: category.name,
              slug: category.slug,
            },
          ],
          tags: article.article_tags.map(article_tag => article_tag.tag.name),
          isExternalLinkEnabled: article.is_external_link_enabled ?? false,
          externalLink: article.external_link ?? null,
        })),
    }))

  return categories
}
